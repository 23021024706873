.nav-items {
    margin:0 auto;
    width:100px;
    display: flex;
    span {
        margin-left: 15px;
        font-size: 18px;
    }
}

   